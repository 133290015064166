<template>
  <div class="schsool">
    <div class="schsoolhader">
      <span>质量提升工程网站后台</span>

      <el-popover
        placement="top"
        width="100"
        v-model="visible"
        class="povervisiblecss"
      >
        <div style="text-align: center; height: 40px; line-height: 40px">
          <el-button
            size="mini"
            type="text"
            style="margin-right: 15px"
            @click="visible = false"
            >取消</el-button
          >
          <el-button type="primary" size="mini" @click="visibleoperate"
            >确定</el-button
          >
        </div>
        <span slot="reference" class="referencecss" style="margin-left: 70%">
          <i class="el-icon-sort" style="transform: rotate(270deg)"></i>
          退出登录</span
        >
      </el-popover>
    </div>

    <div class="schsoolname">
      <div class="admincssleft">
        <div class="admincsstitle">
          <div class="admincssblue"></div>
          <div class="admincsstitleed">学校列表</div>

          <!-- 编辑 -->
          <div class="admincssbianji" @click="confirmschool">
            <span>编辑</span>
          </div>
          <div class="admincssxinzeng" @click="addschooltitle">
            <i class="el-icon-plus" style="color: #fff"></i
            ><span>新增学校</span>
          </div>
        </div>
        <div class="admincsspro">
          <div
            :class="
              schsoolcolorindex == index
                ? 'admincssprotitle schsoolcolor'
                : 'admincssprotitle'
            "
            v-for="(item, index) in schooltitle"
            :key="index"
            @click="schsoolcoloriadd(index)"
          >
            <el-popconfirm
              title="这是一段内容确定删除吗？"
              @confirm="confirmdel(item.id)"
            >
              <i
                class="el-icon-remove-outline"
                style="margin-right: 5%"
                slot="reference"
                v-show="schoolbianji"
              ></i>
            </el-popconfirm>
            {{ item.school_name + "(" + item.school_url + ")" }}
            <i
              class="el-icon-edit"
              style="margin-left: 10%"
              v-show="schoolbianji"
              @click="confirmschoolname(item)"
            ></i>
          </div>
          <div class="adminschooldiv">
            <el-input
              v-show="schoolboolan || schoolnameboolan"
              class="adminschoolname"
              v-model="schoolvalue"
              placeholder="请输入学校名称"
            ></el-input>

            <el-input
              v-show="schoolboolan || schoolnameboolan"
              class="adminschoolname"
              v-model="schoolnewspecial"
              placeholder="请输入学校连接"
            ></el-input>

            <span
              @click="ddschoolconfirm"
              v-show="schoolboolan || schoolnameboolan"
              >确定</span
            >
          </div>
        </div>

        <diavue :Dialogboolan="schooldiaboolan"></diavue>

        <!-- <el-button type="primary" @click="savedata">保存数据</el-button> -->
      </div>
      <div class="admincssright">
        <div class="admincsstitle" v-if="schooltitle[schsoolcolorindex]">
          <div
            class="admincsstitlediv"
            v-for="(itemed, indexed) in schooltitle[schsoolcolorindex].children"
            :key="indexed"
          >
            <i class="el-icon-tickets" style="color: #d9a55a"></i
            ><span class="admincsstit">{{ itemed.name + '('+ itemed.zl_url    + ')' }}</span>

            <span class="adminspan">
              <span
                >账号:<span>{{ itemed.mobile }}</span></span
              >
              <span
                >密码:<span>{{ itemed.show_pwd }}</span></span
              >
            </span>

            <span class="admincsslog" @click="getspecial(itemed)"
              >进入专栏<i class="el-icon-d-arrow-right admincsslogi"></i
            ></span>
          </div>
          <div class="adminspecial" v-show="schoolspecialboolan">
            <el-input
              v-model="schoolspecial"
              placeholder="请输入专栏名称"
            ></el-input>
            <el-input
              v-model="schoolspecialurl"
              placeholder="请输入专栏连接"
            ></el-input>

            <el-button type="primary" @click="confirmspecial">确定</el-button>
          </div>
          <div class="admincssxinzen" @click="addspecial">
            <span>
              <i class="el-icon-plus" style="color: #fff"></i>新增专栏</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import diavue from "../public/Dialog/Dialog.vue"; //弹框
import { addshool, shoolindex, shoolzhuanlan } from "../api/api";

export default {
  data() {
    return {
      schooltitle: [], //设置学校名称

      schooltitleindex: 0, //选中的学校下标

      schoolboolan: false, //是否进行编辑

      schoolvalue: "", //输入的学校的名称

      schoolvalueid: "", //修改学校时的编辑

      schoolnewspecial: "", //新建的学校连接

      schoolnameboolan: false, //编辑学校的名称

      schoolbianji: false, //是否编辑学校

      schsoolcolorindex: 0, //是否选中专栏

      schoolspecial: "", //输入的专栏名称

      schoolspecialurl: "", //新建的专栏的连接

      schoolspecialboolan: false, //是否新建专栏

      schooldiaboolan: false, //弹出的修改框

      visible: false, //退出的弹出框
    };
  },
  mounted() {
    this.shoolindexed();
  },
  methods: {
    //查看学校数据
    shoolindexed() {
      shoolindex().then((res) => {
        console.log(res.data.data);
        this.schooltitle = res.data.data;
        // console.log(this.schooltitle[this.schsoolcolorindex].children);
      });
    },

    //添加学校
    addschooltitle() {
      this.schoolvalue = "";
      this.schoolnewspecial = "";
      this.schoolboolan = !this.schoolboolan;
    },
    //确定添加学校
    ddschoolconfirm() {
      if (this.schoolvalue != "" && this.schoolnewspecial != "") {
        console.log(this.schoolnameboolan);
        console.log(this.schoolboolan);
        if (this.schoolboolan) {
          addshool(this.schoolvalue, this.schoolnewspecial).then((res) => {
            // console.log(res);
            if (res.data.code == 200) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.schoolboolan = !this.schoolboolan;
              this.schoolvalue = "";
              this.schoolnewspecial = "";
              this.shoolindexed();
            }
          });
        } else {
          addshool(
            this.schoolvalue,
            this.schoolnewspecial,
            this.schoolvalueid
          ).then((res) => {
            // console.log(res);
            if (res.data.code == 200) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.schoolboolan = !this.schoolboolan;
              this.schoolnameboolan = !this.schoolnameboolan;
              this.schoolvalue = "";
              this.schoolnewspecial = "";
              this.shoolindexed();
            }
          });
        }
      } else {
        this.$message({
          message: "请先输入学校名称",
          type: "warning",
        });
      }
    },

    //学校新建专栏
    addzhuan(name, url, school_id) {
      shoolzhuanlan(name, url, school_id).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.schoolspecialboolan = false;
          this.shoolindexed();
        }
      });
    },

    //编辑学校
    confirmschool() {
      this.schoolbianji = !this.schoolbianji;
    },
    //编辑学校名称
    confirmschoolname(value) {
      // this.schoolvalue = value;
      this.schoolvalue = value.school_name;
      this.schoolnewspecial = value.school_url;
      this.schoolvalueid = value.id;
      console.log(value.school_name);
      console.log(value.school_url);

      // // this.schoolboolan = !this.schoolboolan;
      this.schoolnameboolan = !this.schoolnameboolan;
    },
    //删除学校
    confirmdel(index) {
      console.log(index);

      addshool("", "", index, 0).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.shoolindexed();
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });

      // if (index > 0) {
      //   this.schsoolcolorindex - 1;
      //   this.schooltitle.splice(index, 1);
      // } else {
      //   this.$message({
      //     message: "无法删除",
      //     type: "warning",
      //   });
      // }
    },
    //选中学校
    schsoolcoloriadd(index) {
      // console.log(index);
      this.schsoolcolorindex = index;
    },
    //新增专栏
    addspecial() {
      console.log("新增");
      this.schoolspecialboolan = !this.schoolspecialboolan;

      this.schoolspecial = "";
      this.schoolspecialurl = "";
    },
    //确定新增专栏
    confirmspecial() {
      console.log("确定的");
      if (this.schoolspecial != "" && this.schoolspecialurl != "") {
        this.addzhuan(
          this.schoolspecial,
          this.schoolspecialurl,
          this.schooltitle[this.schsoolcolorindex].id
        );
      } else {
        this.$message({
          message: "请输入专栏名称",
          type: "warning",
        });
        // this.schoolspecialboolan = !this.schoolspecialboolan;
        // this.schoolspecialboolan = false;
      }
    },
    //进入专栏页面
    getspecial(value) {
      // console.log(value.name);
      this.$router.push({
        path: "/adminproject",
        query: {
          id: value.id,
          name: value.name,
        },
      });
    },

    //删除学校
    delschooltitle() {
      //   console.log("删除");
      if (this.schooltitle.length > 1) {
        this.schooltitle.pop();
      } else {
        this.$message({
          message: "无法删除",
          type: "warning",
        });
      }
    },
    //添加项目
    addproject() {
      this.schooltitle[this.schooltitleindex].chilren.push({ value: "" });
    },
    //删除项目
    delproject() {
      if (this.schooltitle[this.schooltitleindex].chilren.length > 1) {
        this.schooltitle[this.schooltitleindex].chilren.pop();
      } else {
        this.$message({
          message: "无法删除",
          type: "warning",
        });
      }
    },
    //切换学校
    switchoverindex(index) {
      this.schooltitleindex = index;
    },
    //保存数据
    savedata() {
      // console.log("数据保存");
      // this.schooldiaboolan = true;
      // this.$router.push("/adminproject");
    },
    //退出登录
    visibleoperate() {
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push("/login");
    },
  },

  components: {
    diavue,
  },
};
</script>
<style lang="scss" scoped>
//主体
.schsool {
  width: 100%;
  height: 100%;
  background-color: #f8f9ff;
  // display: flex;
  .schsoolhader {
    width: 100%;
    height: 70px;
    background-color: #fff;
    line-height: 70px;
    span {
      margin-left: 4%;
      font-weight: 800;
      color: #323b61;
    }
  }
  .schsoolname {
    display: flex;
  }
  .admincssleft {
    width: 360px;
    margin: 5% auto;
    position: relative;
    // left: 10%;
    /* 设置项目列表 */
    .admincsstitle {
      line-height: 16px;
      display: flex;
      .admincssblue {
        background-color: #323b61;
        width: 4px;
        height: 19px;
        margin-right: 12px;
      }
      .admincsstitleed {
        font-size: 18px;
        font-weight: 900;
        margin-right: 15%;
      }
      .admincssbianji {
        background-color: #ffffff;
        width: 54px;
        height: 30px;
        text-align: center;
        border: 1px solid #323b61;
        border-radius: 4px;
        margin-top: -5px;
        cursor: pointer;
        span {
          line-height: 30px;
          font-size: 14px;
        }
      }
      .admincssxinzeng {
        width: 86px;
        height: 30px;
        text-align: center;
        border: 1px solid #323b61;
        border-radius: 4px;
        margin-top: -5px;
        background-color: #323b61;
        margin-left: 9%;
        cursor: pointer;
        span {
          line-height: 30px;
          font-size: 14px;
          color: #fff;
        }
      }
    }
    // 设置项目内容
    .admincsspro {
      width: 320px;
      margin-top: 20px;
      margin-bottom: 20px;
      height: 100%;
      background-color: #323b61;
      cursor: pointer;
      .admincssprotitle {
        background-color: #46507c;
        height: 60px;
        line-height: 60px;
        color: #fff;
        padding-left: 15px;
        border-top: 1px solid #fff;
      }
      .adminschooldiv {
        margin-top: 15px;
        span {
          margin-left: 35px;
          color: #fff;
          cursor: pointer;
        }
        .adminschoolname {
          width: 70%;
          margin-left: 3%;
          margin-bottom: 4%;
        }
      }
    }
  }
  .admincssright {
    width: 950px;
    height: 320px;
    // background-color: red;
    margin: 5%;
    /* 设置项目列表 */
    .admincsstitle {
      position: relative;
      left: -12%;
      top: 15%;
      .admincsstitlediv {
        position: relative;
        width: 1020px;
        height: 70px;
        background-color: #edf0fd;
        margin-bottom: 10px;
        line-height: 70px;
        padding-left: 20px;
        cursor: pointer;
        i {
          font-size: 18px;
        }
        .admincsstit {
          margin-left: 30px;
        }
        //账号密码
        .adminspan {
          margin-left: 300px;
          span {
            margin-right: 20px;
          }
        }
        .admincsslog {
          // position: relative;
          position: absolute;
          right: 2%;
          color: #939fd6;
          cursor: pointer;
        }
        .admincsslogi {
          position: relative;
          top: 2px;
          left: 10px;
          color: #939fd6;
        }
      }
      .admincsstitlediv:hover {
        background-color: #dfe3f5;
      }
      .admincssxinzen {
        background-color: #323b61;
        width: 100px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: #fff;
        border-radius: 5px;
      }
    }
    .adminspecial {
      width: 50%;
      // height: 70px;
      // line-height: 70px;
      display: flex;
      margin-bottom: 10px;
      .el-input {
        margin-right: 40px;
      }
    }
    .admincssrightpro {
      width: 320px;
      margin-top: 20px;
    }
  }
}

//选中学校的颜色
.schsoolcolor {
  // background-color: #f8f9ff !important;
  border: 1px solid #d9a55a !important;
}

//气泡框
.el-popconfirm {
  background-color: red;
}
</style>