<template>
  <div>
    <el-dialog
      title="提示"
      :visible.sync="Dialogboolan"
      width="30%"
      :before-close="handleClose"
    >
      <span>这是一段信息</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="Dialogboolan = false">取 消</el-button>
        <el-button type="primary" @click="Dialogboolan = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    Dialogboolan: Boolean,
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    handleClose(done) {
    //   this.$confirm("确认关闭？")
    //     .then((_) => {
    //       done();
    //     })
    //     .catch((_) => {});



    console.log(this.Dialogboolan);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>